import { useCookies } from "react-cookie"
import { useRouter } from "next/router"
import dynamic from "next/dynamic"
import { NextPage } from "next"

const HomePage: NextPage = () => {
  const router = useRouter()
  const [cookies] = useCookies(["idToken"])
  if (cookies.idToken) {
    router.push("/dashboard")
  } else {
    router.replace("/activities")
  }
  return (
    <></>
  )
}

export default dynamic(() => Promise.resolve(HomePage), {
  ssr: false
})
